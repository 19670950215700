<template>
	<div class="campaign-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !campaign"
		         v-on:ok="deleteCampaign"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Campaign</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalCampaign" class="font-weight-bold">Campaign No.</label>
					<input type="text" id="modalCampaign" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': campaignNoInvalid}"
					       v-model="campaign"
					       v-on:input="campaignNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveCampaign"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!campaign"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="campaign" class="font-weight-bold">Campaign No.</label>
					<input type="text" id="campaign" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localCampaign.campaignNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="client" class="font-weight-bold">Client</label>
					<input type="text" id="client" class="form-control position-relative"
					       placeholder="Choose Your Intended Client From The Table"
					       v-bind:class="invalidInputs.client"
					       v-bind:value="localCampaign.clientName" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="name" class="font-weight-bold">Name</label>
					<input type="text" id="name" placeholder="Campaign Name" class="form-control position-relative"
					       v-bind:class="invalidInputs.name"
					       v-bind:value="localCampaign.name"
					       v-on:input="updateCampaign('name', $event.target.value)">
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="beginAtDate" class="font-weight-bold text-truncate">Date Begin</label>
						<b-form-datepicker id="beginAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.beginAtDT.date"
						                   v-bind:value="localCampaign.beginAtDT.date"
						                   v-on:input="updateDT('beginAt', 'beginAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="beginAtTime" class="font-weight-bold text-truncate">Time Begin</label>
						<b-form-timepicker id="beginAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.beginAtDT.time"
						                   v-bind:value="localCampaign.beginAtDT.time"
						                   v-on:input="updateDT('beginAt', 'beginAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="endAtDate" class="font-weight-bold text-truncate">Date End</label>
						<b-form-datepicker id="endAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.endAtDT.date"
						                   v-bind:value="localCampaign.endAtDT.date"
						                   v-on:input="updateDT('endAt', 'endAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="endAtTime" class="font-weight-bold text-truncate">Time End</label>
						<b-form-timepicker id="endAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.endAtDT.time"
						                   v-bind:value="localCampaign.endAtDT.time"
						                   v-on:input="updateDT('endAt', 'endAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="descriptions" class="font-weight-bold">Descriptions</label>
					<textarea id="descriptions" rows="6" class="form-control position-relative"
					          v-model="localCampaign.descriptions"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localCampaign.notes"></textarea>
				</div>
			</form>
		</div>
		<div class="table-selects scroll-bar position-relative w-50 h-100 ml-3 rounded d-flex flex-column">
			<table-select title="Client" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="clientsFields"
			              v-bind:data-array="clients"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localCampaign.client]"
			              v-on:row-clicked="selectClient($event)"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent } from "@/utility/helpers";

export default {
	name: "CampaignDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		campaignId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initCampaign();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localCampaign: {
				_id: "",
				campaignNo: "",
				client: "",
				name: "",
				beginAt: "",
				endAt: "",
				descriptions: "",
				notes: "",
				// added properties
				clientName: "",
				beginAtDT: { date: "", time: "" },
				endAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				client: "",
				name: "",
				beginAtDT: { date: "", time: "" },
				endAtDT: { date: "", time: "" },
			},
			clientsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Client No.", key: "clientNo", classBinding: ["f-3"] },
				{ display: "Type", key: "type", classBinding: ["f-2"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			campaignNo: "",
			campaignNoInvalid: false,
		}
	},
	
	computed: {
		campaign() {
			return this.$store.state.contents.campaigns.find(({ _id }) => _id === this.campaignId);
		},
		
		savable() {
			// fall through pattern
			if (!this.campaign) return true;
			// can safely access properties inside this.campaign due to the first if clause in this function
			if (dateTimeDifferent(this.campaign.beginAt, this.localCampaign.beginAt)) return true;
			if (dateTimeDifferent(this.campaign.endAt, this.localCampaign.endAt)) return true;
			const keys = ["client", "name", "descriptions", "notes"];
			return keys.some(key => this.localCampaign[key] !== this.campaign[key]);
		},
		
		clients() {
			return this.$store.state.contents.clients.map(({ _id, name, clientNo, type }) => {
				return {
					_id: _id,
					name: name,
					clientNo: clientNo,
					type: type
				}
			});
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.campaignNo = "";
				this.campaignNoInvalid = false;
			}
		},
		
		initCampaign() {
			if (this.campaign) {
				const obj = {...this.campaign};
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === obj.client);
				// beginAt and endAt must present in the database, need no nullish check
				const beginAtDT = new Date(obj.beginAt);
				const endAtDT = new Date(obj.endAt);
				// added properties
				obj.clientName = client ? `${client.name} (${client.clientNo})` : "";
				obj.beginAtDT = { date: format(beginAtDT, "yyyy-MM-dd"), time: format(beginAtDT, "HH:mm:ss")};
				obj.endAtDT = { date: format(endAtDT, "yyyy-MM-dd"), time: format(endAtDT, "HH:mm:ss")};
				this.localCampaign = obj;
			} else {
				this.localCampaign._id = uuidv4();
				this.localCampaign.campaignNo = "CAM-" + this.localCampaign._id.split("-")[0].toUpperCase();
			}
		},
		
		selectClient(client) {
			this.localCampaign.client = client._id;
			this.localCampaign.clientName = `${client.name} (${client.clientNo})`;
			this.invalidInputs.client = "";
		},
		
		updateCampaign(key, value) {
			this.localCampaign[key] = value;
			// check if value is just white spaces
			this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
		},
		
		updateDT(key, keyDT, dateTime, value) {
			this.localCampaign[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localCampaign[key] = this.localCampaign[keyDT].time ?
					new Date(`${value}T${this.localCampaign[keyDT].time}`).toISOString() : value;
			} else {
				this.localCampaign[key] = this.localCampaign[keyDT].date ?
					new Date(`${this.localCampaign[keyDT].date}T${value}`).toISOString() : value;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const keys = ["client", "name"];
			const dtKeys = ["beginAt", "endAt"];
			keys.forEach(key => {
				if (!this.localCampaign[key] ||
					this.localCampaign[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			dtKeys.forEach(key => {
				if (this.localCampaign[key]) {
					if (!this.localCampaign[`${key}DT`].date) this.invalidInputs[`${key}DT`].date = "invalid";
					if (!this.localCampaign[`${key}DT`].time) this.invalidInputs[`${key}DT`].time = "invalid";
				} else {
					this.invalidInputs[`${key}DT`].date = "invalid";
					this.invalidInputs[`${key}DT`].time = "invalid";
				}
			});
			return Object.keys(this.invalidInputs).some(key => {
				if (key.includes("DT")) {
					return this.invalidInputs[key].date === "invalid" || this.invalidInputs[key].time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			});
		},
		
		async saveCampaign() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["clientName", "beginAtDT", "endAtDT"];
				const campaign = {...this.localCampaign};
				deleteKeys.forEach(key => delete campaign[key]);
				await this.$store.dispatch("contents/updateContent", { key: "campaigns", value: campaign});
				if (this.campaignId) {
					this.initCampaign();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/project/campaign/${campaign._id}`);
					// need to run initCampaign after route change since campaignId prop was undefined
					this.initCampaign();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Campaign Created!";
				}
			}
		},
		
		async deleteCampaign(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.campaignNo !== this.campaign.campaignNo) this.campaignNoInvalid = true;
			} else if (this.campaignNo !== this.campaign.campaignNo) {
				bvModalEvent.preventDefault();
				this.campaignNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "campaigns", _id: this.campaign._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/project/campaign");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/project/campaign");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>